<template>
    <div class="newslist">
        <div style="position: relative;height:2rem">
            <img
                class="bannerImg"
                src="../assets/images/aboutus/newsList.png"
                alt=""
            />
            <div class="banners">
                <img class="bannerImg" src="../assets/images/aboutus/newstitle.png" />
             </div>
        </div>
        <!-- <div class="breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/aboutus' }">关于我们</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/news">新闻资讯</a></el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->
        <div class="page_wrap" style="margin-top: 0.4rem;">
            <div class="page_content" style="maxWidth:15rem" v-if="photoList.length!=0">
                <div v-for="item in photoList" :key="item.index" class="content-single" @click="goDetails(item)">
                    <div class="newsList-item news-flex" >
                        <el-image :src="'/oss'+item.smallImgPath" class="newsList-item-left">
                            <div slot="error" class="image-slot" style='width:100%;height:100%;background:#E4E7ED;text-align:center;display:flex;justify-content:center;flex-direction:column;color:#909399'>
                                <i class="el-icon-picture-outline"></i>暂无图片
                            </div>
                        </el-image>
                        <!-- <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${'/oss'+item.smallImgPath})`}"></div> -->
                        <div class="newsList-item-right">
                            <div class="newsItem-time">
                                <div style="font-size: 0.18rem;font-weight: bold;color: #333333;">{{  formatTime(item.createDate) }}</div>
                                <!-- <div style="font-size: 0.18rem;color: #A8A8A8;">{{ item.createDate.slice(11,19) }}</div> -->
                            </div>
                            <div class="newsItem-title"  :title="item.title">{{ item.title }}</div>
                            <div class="newsItem-content">{{item.summarize}}</div>
                            <div class="newsItem-detail" @click="goDetails(item)">查看详情 >></div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        prev-text="<上一页"
                        next-text="下一页>"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="prev, pager, next, jumper"
                        :total="totalPage">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import api from '@/api/api'
import newsData from '@/assets/js/news.js'
import DateUtils from "@/assets/filters/dateUtils";
import photoList from './photoList.vue'
export default {
    components:{ photoList },
    created(){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.queryList()
    },
    data(){
        return{
            pagenum:1,
            pagesize:10,
            totalPage:'',
            imglist:[
                require('@/assets/images/newsBannar.png'),
                require('@/assets/images/newsBannar2.png'),
            ],
            photoList:[],
            currentPage: '',
        }
    },
    methods:{
      handleSizeChange(val) {
          this.pagesize=val
          this.queryList()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
          this.pagenum=val
          this.queryList()
        console.log(`当前页: ${val}`);
      },
         formatTime(val){
           return DateUtils.dateFormat(new Date(val)).slice(0,10)
        },
        //  goDetails(item){
        //      localStorage.setItem('info',JSON.stringify(item))
        //     //  console.log(1111)
        //      this.$router.push({path:'/newsDetails',})
        //  },
        goDetails(val){
            if(val.wxUrl){
               window.open(val.wxUrl)
               return 
            }
            this.$router.push({
                path:'/newsDetails',
                query:{id:val.id}
                })
        },
         queryList(){
             api.newslist({
                pageNum: this.pagenum,
                pageSize: this.pagesize
                }).then(res=>{
                 if(res.code==200 && res.data.list){
                   this.photoList=res.data.list
                   this.totalPage=res.data.totalRows
                 }
             })
         }
    },

}
</script>
<style lang="scss" scoped>
.newslist{
    background: #F5F5F5;
    .bannerImg {
    height: 2rem;
    }
    .banners {
        width: 12rem;
        margin:0 auto;
        position: absolute;
        top:32%;
        left: 16%;
       
        img {
        width:2.04rem; 
        height: 0.9rem;
        }
    }
    .breadcrumb{
        width: 12rem;
        margin:0 auto;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #A8A8A8;
    }
}
.page_wrap{
    .page_content{
        width: 12rem;
        margin:0 auto;
        .content-single{
            margin-bottom: 0.4rem;
           background: #ffffff;
           cursor: pointer;

        }
        .pagination{
            display: flex;
            justify-content: center;
            padding-bottom: 0.6rem;
        }
    }
}

.news-flex{
    display: flex;
    justify-content: space-between;
}
.newsList-item{
    height: 2.6rem;
    &-left{
        width:4rem;
        height: 2.6rem;
    }
    &-right{
        padding:0.3rem;
        width: calc(100% - 4rem);
        position: relative;
        .newsItem-time{
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.31rem;
        }
        .newsItem-title{
            text-align: left;
            font-size: 0.18rem;
            color:#333;
            font-family: Source Han Sans CN;
            font-weight: bold;
            margin-bottom: 0.3rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;            
        }
        .newsItem-title:hover{
            color:#0061E3;
        }
        .newsItem-content{
            font-size:0.14rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:3;
        }
        .newsItem-detail{
            position: absolute;
            bottom: 0.31rem;
            right: 0.3rem;
            text-align: right;
            font-size: 0.14rem;
            font-family: Source Han Sans CN;
            color: #333333;
        }
        .newsItem-detail:hover{
            font-weight: bold;
            color: #0061E3;
        }
    }
}

.newsList-item:hover {
    background: #f0f6ff;
    cursor: pointer;
}
</style>
<style>
.newslist .el-breadcrumb{
    line-height: 0.74rem;
}
.newslist .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
    color: #A8A8A8;
}
.newslist .el-breadcrumb__item:last-child .el-breadcrumb__inner a{
    color:#000000
}
.newslist .el-pagination .btn-prev{
    background: #F5F5F5;
}
.newslist .el-pagination .btn-next{
    background: #F5F5F5;
}
.newslist .el-pager li {
    background: #F5F5F5;
}
.el-pagination__editor.el-input .el-input__inner{
    background: #F5F5F5;
}
</style>